// ** UseJWT import to get config
import TokenStorage from '@src/@core/services/storage/token';
import AclStorage from '@src/@core/services/storage/acl';
import UserDataStorage from '@src/@core/services/storage/userData';
import BookmarksStorage from '@src/@core/services/storage/bookmarks';
import useJwt from '@src/auth/jwt/useJwt'
import http from '../../../@core/services/http';
import { toast } from 'react-toastify';

const config = useJwt.jwtConfig

const notifyError = (error) => toast.error(error, { hideProgressBar: true, position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

// ** Handle User Login
export const handleLogin = data => {
  return dispatch => {
    dispatch({
      type: 'LOGIN',
      data,
      config,
      [config.storageTokenKeyName]: data[config.storageTokenKeyName],
      [config.storageRefreshTokenKeyName]: data[config.storageRefreshTokenKeyName]
    })

    // ** Add to user, accessToken & refreshToken to localStorage
    localStorage.setItem('userData', JSON.stringify(data))
    localStorage.setItem(config.storageTokenKeyName, JSON.stringify(data.accessToken))
    localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(data.refreshToken))
  }
}

// ** Handle User Logout
export const handleLogout = (withNotification = false) => {
  return async dispatch => {
    if (withNotification) {
      notifyError('Your session is ended');
    }
    dispatch({ type: 'LOGOUT', [config.storageTokenKeyName]: null, [config.storageRefreshTokenKeyName]: null })
    // ** Remove user, accessToken & refreshToken from localStorage
    try {
      await http.post('/auth/logout');
    } catch (err) {
      console.log('err');
    }
    UserDataStorage.delete();
    TokenStorage.delete();
    AclStorage.delete();
    BookmarksStorage.delete();
    window.location.reload();
  }
}
