export const enableEntityInState = (state, entityId, flag) => {
  const entities = state.data;
  const findEntity = entities.findIndex(u => u.id === entityId);
  entities[findEntity] = {
    ...entities[findEntity],
    isEnable: flag,
  }

  return {
    ...state,
    data: entities,
  }
}