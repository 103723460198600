import { enableEntityInState } from "../helpers";

export const ENTITY = 'PENDING_KYC';

const initialState = {
  data: [],
  total: 1,
  loading: false,
  error: null,
  meta: {},
  isModalOpen: false,
  selectedRow: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `GET_${ENTITY}_REQUEST`:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case `GET_${ENTITY}_SUCCESS`:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        loading: false,
        meta: action.meta,
      }
    case `GET_${ENTITY}_ERROR`:
      return {
        ...state,
        error: action.error,
        loading: false,
        data: [],
        total: 1,
      }
    case `TOGGLE_VIEW_${ENTITY}_MODAL`:
      return {
        ...state,
        isModalOpen: action.flag,
        selectedRow: action.selectedRow,
      }
    default:
      return { ...state }
  }
}
export default reducer
