// ** Initial State
const initialState = {
  pages: [],
  loading: false,
  error: null,
  total: 1,
  meta: {},
}

const cms = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PAGES_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'GET_PAGES':
      return {
        ...state,
        pages: action.data,
        total: action.totalPages,
        loading: false,
        meta: action.meta,
      }
    case 'GET_PAGES_ERROR':
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return { ...state }
  }
}
export default cms
