import { enableCurrencyInState } from "./helpers"

// ** Initial State
const initialState = {
  data: [],
  total: 1,
  loading: false,
  error: null,
  showEditCurrencyModal: false,
  selectedCurrency: {},
  meta: {},
}

const currencyPairs = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CURRENCY_PAIRS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'GET_CURRENCY_PAIRS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        loading: false,
        meta: action.meta,
      }
    case 'GET_CURRENCY_PAIRS_ERROR':
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case 'TOGGLE_EDIT_CURRENCY_MODAL':
      return {
        ...state,
        showEditCurrencyModal: action.flag,
        selectedCurrency: action.selectedCurrency,
      }
    case 'ENABLE_CURRENCY':
      return enableCurrencyInState(state, action.currencyId, action.flag)
    default:
      return { ...state }
  }
}
export default currencyPairs
