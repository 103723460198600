// ** Initial State
const initialState = {
  data: [],
  total: 1,
  loading: false,
  error: null,
  editPrecisionModalShow: false,
  selectedCurrency: {},
  meta: {},
}

const currencies = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CURRENCIES_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'GET_CURRENCIES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        loading: false,
        meta: action.meta,
      }
    case 'GET_CURRENCIES_ERROR':
      return {
        ...state,
        error: action.error,
        loading: false,
        data: [],
        total: 1,
      }
    case 'TOGGLE_EDIT_PRECISION_MODAL':
      return {
        ...state,
        editPrecisionModalShow: action.flag,
        selectedCurrency: action.selectedCurrency,
      }
    default:
      return { ...state }
  }
}
export default currencies
