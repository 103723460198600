export const enableCurrencyInState = (state, currencyId, flag) => {
  const feePlans = state.data;
  const findFeePlanId = feePlans.findIndex(u => u.id === currencyId);
  feePlans[findFeePlanId] = {
    ...feePlans[findFeePlanId],
    isEnable: flag,
  }

  return {
    ...state,
    data: feePlans,
  }
}