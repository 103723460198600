import { formatEnableRole } from "./helpers"

// ** Initial State
const initialState = {
  roles: [],
  loading: false,
  error: null,
  modalShow: false,
  selectedRole: null,
  modalType: '',
  selectedPermissionRole: {},
}

const roles = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ROLES_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'GET_ROLES':
      return {
        ...state,
        roles: action.data,
        loading: false,
      }
    case 'GET_ROLES_ERROR':
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case 'TOGGLE_MODAL':
      return {
        ...state,
        modalShow: action.modalShow,
        modalType: action.modalType,
        selectedRole: action.selectedRole,
      }
    case 'SELECT_PERMISSION_ROLE':
      return {
        ...state,
        selectedPermissionRole: action.selectedPermissionRole,
      }
    case 'ENABLE_ROLE':
      return formatEnableRole(state, action.roleId, action.flag);
    default:
      return { ...state }
  }
}
export default roles
