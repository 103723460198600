export const ENTITY = 'ORDERS';

const initialState = {
  data: [],
  total: 1,
  meta: {},
  loading: false,
  error: null,
  isModalShow: false,
  isCancelOrderModalShow: false,
  isRefundOrderModalShow: false,
  selectedRow: null,
  selectedOrderForCancel: null,
  selectedOrderForRefund: null,
  isChangeAffModalShow: false,
  selectedOrderForChangeAff: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `GET_${ENTITY}_REQUEST`:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case `GET_${ENTITY}_SUCCESS`:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        meta: action.meta,
        loading: false,
      }
    case `GET_${ENTITY}_ERROR`:
      return {
        ...state,
        error: action.error,
        loading: false,
        data: [],
        total: 1,
      }
    case `TOGGLE_VIEW_${ENTITY}`:
      return {
        ...state,
        isModalShow: action.flag,
        selectedRow: action.selected,
      }
    case `TOGGLE_CANCEL_ORDER_MODAL_${ENTITY}`:
      return {
        ...state,
        isCancelOrderModalShow: action.flag,
        selectedOrderForCancel: action.selected,
      }
    case `TOGGLE_REFUND_ORDER_MODAL_${ENTITY}`:
      return {
        ...state,
        isRefundOrderModalShow: action.flag,
        selectedOrderForRefund: action.selected,
      }
    case `TOGGLE_CHANGE_AFF_MODAL_${ENTITY}`:
      return {
        ...state,
        isChangeAffModalShow: action.flag,
        selectedOrderForChangeAff: action.selected,
      }
    default:
      return { ...state }
  }
}
export default reducer
