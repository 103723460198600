import { enableEntityInState } from "../helpers";

export const ENTITY = 'PAYMENT_METHODS';

const initialState = {
  data: [],
  total: 1,
  loading: false,
  error: null,
  selectedPaymentMethod: {},
  editLoading: false,
  meta: {},
}

const paymentMethods = (state = initialState, action) => {
  switch (action.type) {
    case `GET_${ENTITY}_REQUEST`:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case `GET_${ENTITY}_SUCCESS`:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        loading: false,
        meta: action.meta,
      }
    case `GET_${ENTITY}_ERROR`:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case `SELECT_${ENTITY}_REQUEST`:
      return {
        ...state,
        editLoading: true,
      }
    case `SELECT_${ENTITY}`:
      return {
        ...state,
        selectedPaymentMethod: action.selectedPaymentMethod,
        editLoading: false,
      }
    case `SELECT_${ENTITY}_ERROR`:
      return {
        ...state,
        editLoading: false,
      }
    case `ENABLE_${ENTITY}`:
      return enableEntityInState(state, action.paymentMethodId, action.flag)
    default:
      return { ...state }
  }
}
export default paymentMethods
