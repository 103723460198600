export const baseURL = process.env.REACT_APP_API_URL + '/admin/v1';
export const csrfURL = process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie';
export const csrfCookie = process.env.REACT_APP_CSRF_COOKIE;
export const sessionAuthMethod = 'session';

export const initialSortState = {
  field: '',
  direction: '',
};

export const notFilterFields = ['field', 'direction', 'page', 'perPage'];

export const getDataOnMount = (location, setFilter, setSort, setCurrentPage, getDataDispatcher, setRowsPerPage = () => {}) => {
  let filterObj = {};
  let sortObj = {};
  let page = 1;
  let perPage = 10;
  if (location.search) {
    const params = location.search.replace('?', '').split('&');
    params.forEach(p => {
      const value = p.split('=')[1];
      const param = p.split('=')[0];
      if (!notFilterFields.includes(param)) {
        filterObj[param] = decodeURIComponent(value);
      } else if (param === 'page') {
        page = value;
      } else if (param === 'perPage') {
        perPage = value;
      } else {
        sortObj[param] = value;
      }
    })
    setFilter(filterObj);
    setSort(sortObj);
    setCurrentPage(page);
    setRowsPerPage(perPage);
  }
  getDataDispatcher(page, filterObj, sortObj, perPage);
}

export const formSearchUrl = (filter, notSearchParams = []) => {
  let str = '';
  Object.keys(filter).map(param => {
    if (param && filter[param] !== '' && !(notSearchParams.includes(param))) {
      str += `&${param}=${filter[param]}`
    }
  })
  return str;
}

export const formSortUrl = (sort) => {
  let str = '';
  Object.keys(sort).map(param => {
    if (sort[param]) {
      str += `&${param}=${sort[param]}`
    }
  })
  return str;
};

export const mimetypes =  {
  'image/jpeg': 'jpeg',
  'text/html': 'html',
  'text/css': 'css',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/x-ms-bmp': 'bmp',
  'image/bmp': 'bmp',
  'application/pdf': 'pdf',
  'text/plain': 'txt',
  'application/msword': 'doc',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xslx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.oasis.opendocument.text': 'odt',
}