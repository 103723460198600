export const ENTITY = 'TRANSACTIONS_REFUND';

const initialState = {
  data: [],
  total: 0,
  meta: {},
  loading: false,
  error: null,
  isRefundApproveModalShow: false,
  selectedTrxForRefund: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `GET_${ENTITY}_REQUEST`:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case `GET_${ENTITY}_SUCCESS`:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        loading: false,
        meta: action.meta,
      }
    case `GET_${ENTITY}_ERROR`:
      return {
        ...state,
        error: action.error,
        loading: false,
        data: [],
        total: 0,
        meta: {},
      }
    case `TOGGLE_REFUND_APPROVE_MODAL_${ENTITY}`:
      console.log('action', action);
      return {
        ...state,
        isRefundApproveModalShow: action.flag,
        selectedTrxForRefund: action.selected,
      }
    default:
      return { ...state }
  }
}
export default reducer
