export const enableUserInState = (state, userId, flag) => {
  const users = state.data;
  const findUserIndex = users.findIndex(u => u.id === userId);
  users[findUserIndex] = {
    ...users[findUserIndex],
    isEnable: flag,
  }
  return {
    ...state,
    data: users,
  }
}