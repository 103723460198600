export const formatEnableRole = (state, roleId, flag) => {
  const roles = state.roles;
  const index = roles.findIndex(r => r.id === roleId);
  roles[index] = {
    ...roles[index],
    isEnable: flag,
  }
  return {
    ...state,
    roles: roles
  }
}