// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import http from './@core/services/http'
import { handleLogout } from './redux/actions/auth';
import TokenStorage from './@core/services/storage/token';
import AclStorage from './@core/services/storage/acl';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const bootstrapAsync = async () => {
  if (TokenStorage.get()) {
   try {
    const {data} = await http.get('/my/acl');
    AclStorage.save(data);
    store.dispatch({
      type: 'SET_ACL',
      data: data
    })
   } catch (err) {
     store.dispatch(handleLogout())
   };
  }

  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <LazyApp />
          <ToastContainer newestOnTop />
        </ThemeContext>
      </Suspense>
    </Provider>,
    document.getElementById('root')
  )
}

bootstrapAsync();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
