import axios from 'axios';
import { toast } from 'react-toastify';
import { store } from '../../../redux/storeConfig/store';
import { handleLogout } from '@store/actions/auth'
import TokenStorage from '../storage/token';
import LastViewedPageStorage from '../storage/lastViewedPage';
import {baseURL, csrfCookie, sessionAuthMethod} from '../../constants';

const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}


const requiredAuthBySession = process.env.REACT_APP_AUTH_METHOD === sessionAuthMethod;

const ERROR_DISPLAY_TIMEOUT = 3000;
let isErrorDisplayed = false;

const notifyError = (error) => toast.error(error, { hideProgressBar: true, position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

const http = axios.create({
    withCredentials: requiredAuthBySession,
    baseURL,
    headers: {'Content-Type': 'application/json'},
});

if (requiredAuthBySession) {
    http.interceptors.request.use(
        async request => {
            const csrf = getCookie(csrfCookie);
            request.withCredentials = true;
            if (csrf) {
                request.headers.common['X-XSRF-TOKEN'] = csrf;
            }
            return request;
        },
        error => Promise.reject(error),
    );
} else {
    http.interceptors.request.use(
        async request => {
            const token = TokenStorage.get();
            const newRequest = {...request};
            if (token) {
                newRequest.headers.Authorization = `Bearer ${token}`;
            }
            return newRequest;
        },
        error => Promise.reject(error),
    );
}

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401 && error?.config?.url !== "/auth/logout" && error?.config?.url !== "/my/acl") {
      store.dispatch(handleLogout());
      console.log('window.location.pathname', window.location.pathname);
      debugger;
      if (window.location.pathname !== '/login' && window.location.pathname !== '/not-authorized' && window.location.pathname !== '/home') {
        LastViewedPageStorage.save(`${window.location.pathname}${window.location.search}`);
      }
      window.location.replace('/login');
    }
    if (error?.response?.status === 422 && error?.response?.data?.errors?.field?.length && error?.response?.data?.errors?.field[0] === "Undefined sort parameter.") {
      const field = error?.response?.config?.url?.split('sort[field]=')?.pop()?.split('&').length && error?.response?.config?.url?.split('sort[field]=')?.pop()?.split('&')[0];
      notifyError(`Undefined sort parameter: ${field}`);
    }
    if (error?.response?.status === 500) {
      if (!isErrorDisplayed) {
        notifyError(error.response?.data?.message || 'Server error');
        isErrorDisplayed = true;
        setTimeout(() => isErrorDisplayed = false, ERROR_DISPLAY_TIMEOUT);
      }
    }
    return Promise.reject(error)
  },
)

export default http;
