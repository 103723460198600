// ** Initial State
const initialState = {
  data: [],
  total: 1,
  loading: false,
  error: null,
  editAffGroupModalOpen: false,
  selectedAffGroup: {},
  type: '',
  meta: {},
}

const affiliateGroups = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_AFFILIATES_GROUPS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'GET_AFFILIATES_GROUPS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        loading: false,
        meta: action.meta,
      }
    case 'GET_AFFILIATES_GROUPS_ERROR':
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case 'TOGGLE_EDIT_AFF_GROUP':
      return {
        ...state,
        editAffGroupModalOpen: action.flag,
        selectedAffGroup: action.selectedAffGroup,
        type: 'edit',
      }
    case 'TOGGLE_ADD_AFF_GROUP':
      return {
        ...state,
        editAffGroupModalOpen: action.flag,
        type: 'add',
      }
    default:
      return { ...state }
  }
}
export default affiliateGroups
