import { rejectTransactionInState } from "./helpers";

export const ENTITY = 'TRANSACTIONS_DEPOSIT';

const initialState = {
  data: [],
  total: 0,
  meta: {},
  loading: false,
  error: null,
  isModalShow: false,
  isAmlModalShow: false,
  selectedRow: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `GET_${ENTITY}_REQUEST`:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case `GET_${ENTITY}_SUCCESS`:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        loading: false,
        meta: action.meta,
      }
    case `GET_${ENTITY}_ERROR`:
      return {
        ...state,
        error: action.error,
        loading: false,
        data: [],
        total: 0,
        meta: {},
      }
    case `TOGGLE_${ENTITY}_MODAL`:
      return {
        ...state,
        isModalShow: action.flag,
        selectedRow: action.selectedRow,
      }
    case `TOGGLE_AML_${ENTITY}_MODAL`:
      return {
        ...state,
        isAmlModalShow: action.flag,
        selectedRow: action.selectedRow,
      }
    case `REJECT_${ENTITY}_TRANSACTION`:
      return rejectTransactionInState(state, action.id, "Rejected");
    default:
      return { ...state }
  }
}
export default reducer
