export const enableFeePlanInState = (state, feePlanId, flag) => {
  const feePlans = state.data;
  const findFeePlanId = feePlans.findIndex(u => u.id === feePlanId);
  feePlans[findFeePlanId] = {
    ...feePlans[findFeePlanId],
    isEnable: flag,
  }

  return {
    ...state,
    data: feePlans,
  }
}