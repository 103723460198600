// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from './users';
import roles from './roles';
import cms from './cms';
import clients from './clients';
import feePlans from './fee-plans';
import currencies from './currencies';
import currencyPairs from './currency-pairs';
import affiliates from './affiliates';
import affiliateGroups from './affiliate-groups';
import paymentMethods from './payment-methods';
import notifications from './notifications';
import pendingKyc from './pending-kyc';
import auditLog from './audit-log';
import deposit from './transactions/deposit';
import exchange from './transactions/exchange';
import withdraw from './transactions/withdraw';
import refund from './transactions/refund';
import orders from './orders';
import clientAffiliates from './clients/affiliates';
import acl from './acl';
import paymentAccounts from './payment-accounts';
import sentNotifications from './sent-notifications';


const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  users,
  roles,
  cms,
  clients,
  feePlans,
  currencies,
  currencyPairs,
  affiliates,
  affiliateGroups,
  paymentMethods,
  notifications,
  pendingKyc,
  auditLog,
  deposit,
  exchange,
  withdraw,
  refund,
  orders,
  clientAffiliates,
  acl,
  paymentAccounts,
  sentNotifications,
})

export default rootReducer
