import { enableUserInState } from "./helpers"

// ** Initial State
const initialState = {
  data: [],
  total: 1,
  selectedUser: {
    name: '',
    email: '',
    userRoleId: '',
  },
  showEditSidebar: false,
  loadingUsers: false,
  error: null,
  meta: {},
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_REQUEST':
      return {
        ...state,
        loadingUsers: true,
        error: null,
      }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        loadingUsers: false,
        meta: action.meta,
      }
    case 'GET_DATA_ERROR':
      return {
        ...state,
        error: action.error,
        loadingUsers: false,
        data: [],
        total: 1,
      }
    case 'TOGGLE_EDIT_SIDEBAR':
      return { ...state, showEditSidebar: action.showEditSidebar }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state }
      case 'ENABLE_USER':
        return enableUserInState(state, action.userId, action.flag)
    case 'DELETE_USER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default users
