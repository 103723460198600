export const ENTITY = 'TRANSACTIONS_EXCHANGE';

const initialState = {
  data: [],
  total: 0,
  meta: {},
  loading: false,
  error: null,
  sourceAmount: 0,
  targetAmount: 0,

}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `GET_${ENTITY}_REQUEST`:
      return {
        ...state,
        loading: true,
        error: null,
        sourceAmount: 0,
        targetAmount: 0,
      }
    case `GET_${ENTITY}_SUCCESS`:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        loading: false,
        meta: action.meta,
        sourceAmount: action.sourceAmount,
        targetAmount: action.targetAmount,
      }
    case `GET_${ENTITY}_ERROR`:
      return {
        ...state,
        error: action.error,
        loading: false,
        data: [],
        total: 0,
        meta: {},
        sourceAmount: 0,
        targetAmount: 0,
      }
    default:
      return { ...state }
  }
}
export default reducer
