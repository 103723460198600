export default class Storage {
  constructor(type) {
    this.type = type;
  }

  get = () => {
    const item = localStorage.getItem(`AdminExchange-${this.type}`);

    return JSON.parse(item);
  };

  save = item => localStorage.setItem(`AdminExchange-${this.type}`, JSON.stringify(item));

  delete = () => localStorage.removeItem(`AdminExchange-${this.type}`);

}
