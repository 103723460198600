// ** Initial State
const initialState = {
  data: {},
  loading: false,
}

const acl = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACL':
      return {
        ...state,
        data: action.data,
      }
    case 'SET_ACL_REQUEST':
      return {
        ...state,
        // loading: true,
      }
    case 'SET_ACL_SUCCESS':
      return {
        ...state,
        // loading: false,
        data: action.data,
      }
    default:
      return { ...state }
  }
}
export default acl
